import React from "react"
import { Col, Row, Typography } from "antd"
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Shell from "../components/Shell/Shell"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import PackagesWidget from "../components/PackagesWidget/PackagesWidget"
import MediaContext from "@avldev/gatsby-theme-core-ui/src/components/MediaContext"

const description = `Check out these beautiful images of The Residences at Biltmore All-Suite Hotel located near Biltmore and very close to downtown Asheville, NC.`

const col = {
  xs: 23,
  sm: 22,
  md: 20,
  lg: 18,
  xl: 16,
  xxl: 14,
  style: { maxWidth: 1360 },
}

const active = `2px solid #333`
const inactive = `2px solid #F1F1EB`
const arrow = {
  alignItems: `center`,
  background: `rgba(0,0,0,0.65)`,
  border: 0,
  borderRadius: `100%`,
  color: `#fff`,
  cursor: `pointer`,
  display: `flex`,
  fontSize: 28,
  height: 40,
  justifyContent: `center`,
  outline: `none`,
  padding: 4,
  position: `absolute`,
  textAlign: `center`,
  width: 40,
  zIndex: 1,
}
const imgCounter = {
  background: `rgba(0,0,0,0.65)`,
  borderRadius: 4,
  color: `#fff`,
  fontSize: 12,
  right: 4,
  paddingLeft: 8,
  paddingRight: 8,
  position: `absolute`,
  top: `33vh`,
  zIndex: 1,
}

export default ({ data: { gallery, hero }, location }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const thumbContainer = React.createRef()
  const galleryColRef = React.createRef()
  const galleryWrapperRef = React.createRef()
  const image = React.createRef()
  const { reduceMotion } = React.useContext(MediaContext)

  React.useEffect(() => {
    if (galleryColRef.current && galleryWrapperRef.current) {
      const dist = currentIndex * galleryColRef.current.clientWidth
      galleryColRef.current.scrollTo({
        left: dist,
        top: 0,
        behavior: reduceMotion ? `auto` : `smooth`,
      })
    }
  }, [currentIndex, galleryColRef, galleryWrapperRef, reduceMotion])

  const onNext = () => {
    let next = currentIndex + 1
    if (next > gallery.images.length - 1) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(next)
    }
  }

  const onPrev = () => {
    const prev = currentIndex - 1
    if (prev < 0) {
      setCurrentIndex(gallery.images.length - 1)
    } else {
      setCurrentIndex(prev)
    }
  }

  const galleryWrapperStyle = {
    alignItems: `center`,
    display: `flex`,
    height: `35vh`,
    width: `calc(100% * ${gallery.images.length})`,
  }

  return (
    <Shell>
      <SEO
        meta={{
          description,
          image: hero.fluid.src,
          imageAlt: hero.description,
        }}
        title="Photo Gallery, All Suite Hotel Near Biltmore - The Residences at Biltmore"
        url={location.href}
      />
      <Hero contentfulAsset={hero} height="40vh" />
      <BookingBar />

      <Row
        align="middle"
        gutter={[32, 32]}
        justify="center"
        style={{ marginBottom: 24 }}
      >
        <Col {...col} style={{ marginTop: 32 }}>
          <Typography.Title>Photo Gallery</Typography.Title>
          <Typography.Paragraph>
            Whether you come for the spacious accommodations, a meeting or an
            event, or a delicious meal and a couple of beers after work at our
            on-site restaurant Azalea Bar & Kitchen, The Residences at Biltmore
            has so much to offer Asheville visitors. See for yourself!
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row align="middle" justify="center" type="flex">
        <Col {...col} style={{ paddingBottom: 48 }}>
          <Row align="middle" justify="center" type="flex">
            <button onClick={onNext} style={{ ...arrow, right: 15 }}>
              <ArrowRightOutlined />
            </button>
            <button onClick={onPrev} style={{ ...arrow, left: 15 }}>
              <ArrowLeftOutlined />
            </button>
            <div style={imgCounter}>
              {currentIndex + 1} of {gallery.images.length}
            </div>
            <Col
              ref={galleryColRef}
              span={24}
              style={{
                background: `rgba(0,0,0,0.05)`,
                border: `1px solid rgba(0,0,0,0.05)`,
                borderRadius: 4,
                overflow: `hidden`,
              }}
            >
              <div ref={galleryWrapperRef} style={galleryWrapperStyle}>
                {gallery.images.map(({ description, fluid }, i) => (
                  <Img
                    key={i}
                    alt={description}
                    fluid={fluid}
                    imgStyle={{ objectFit: `contain` }}
                    style={{
                      height: `100%`,
                      width: `100%`,
                    }}
                  />
                ))}
              </div>
            </Col>
          </Row>
          <Row
            align="middle"
            gutter={[12, 12]}
            justify="start"
            ref={thumbContainer}
            type="flex"
            style={{ marginTop: 12 }}
          >
            {gallery.images.map((img, i) => (
              <Col
                key={i}
                xs={12}
                sm={8}
                md={6}
                lg={4}
                ref={currentIndex === i ? image : null}
                style={{ height: 150 }}
              >
                <button
                  onClick={() => setCurrentIndex(i)}
                  style={{
                    border: currentIndex === i ? active : inactive,
                    borderRadius: 4,
                    cursor: `pointer`,
                    height: `100%`,
                    margin: 0,
                    outline: `none`,
                    padding: 0,
                    width: `100%`,
                  }}
                >
                  <Img
                    alt={img.description}
                    fluid={img.fluid}
                    style={{
                      borderRadius: 2,
                      height: `100%`,
                      width: `100%`,
                    }}
                  />
                </button>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <PackagesWidget />
    </Shell>
  )
}

export const query = graphql`
  query {
    hero: contentfulAsset(title: { eq: "RAB - Home Hero 1" }) {
      ...HeroFluid
    }
    gallery: contentfulImageGallery(
      name: { eq: "Photo Gallery Page" }
      business: { elemMatch: { name: { eq: "Residences at Biltmore" } } }
    ) {
      ...ImageGallery
    }
  }
`
